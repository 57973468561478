@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-nav li {
  margin-right: 25px;
  border-bottom: 5px solid transparent;
  padding-top: 5px;
  font-weight: 600;
  color: #d6d6d6;
  padding-bottom: 10px;
  margin-bottom: 0.6px;
}
.logo {
  width: 130px;
}
.copyright {
  background: #0f4cd1;
  color: #ffffff;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: 20px;
  border-top: 1px solid rgba(255,255,255,0.1);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.3);
}

h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #444;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
footer {
  padding-top: 25px;
  background: rgb(37, 99, 235);
  border-top: 1px solid #dddddd;
  color: #ffffff;
}
footer h3 {
  color: inherit;
}

.tracker-img-sizer img {
  margin-right: 10px;
  width: auto !important;
  max-width: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem !important;
}
.why-us-section {

}

.counter {
  text-align: center;
  font-weight: 500;
  border: 1px solid #f0f0f0;
  margin-top: 10px;
}

.counter-digit {
  font-size: 70px;
  font-weight: 500;
  line-height: 1.2;
}

.counter-text {
  font-size: 25px;
}


.graphs-section {
  background: #f4f3f361;
  border-bottom: 1px solid #ffffff;
  box-shadow: -1px 0 3px rgba(0,0,0, .3);
}

.page-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url("../src/assets/img/page-header-banner.jpg");
  border-bottom: 1px solid #dddddd6b;
  border-top: 1px solid #dddddd6b;
  text-transform: uppercase;
  font-weight: 500;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  font-size: 40px;
  margin-bottom: 20px;
}

.team-pp-photo {
  max-width: 100px;
}
.row > div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar .row > div {
  padding-top: 0;
  padding-bottom: 0;
}
.projects-grid-logos {
  width: 100px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.react-grid-item {
  min-height: auto !important;
}
.header-menu .active, .header-menu li:hover {
  color: #ffffff;
  border-bottom-color: #ffffff;
  transition: all ease-in 200ms;
  /* font-weight: bold; */
}

.std-list li {
  padding-top: 7px;
}
.carousel-section {
  padding-top: 0px;
  padding-bottom: 6px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background: radial-gradient(circle, #040466 90%, #6b0000 120%);
  background-color: #040466;
  min-height: 500px;
}
.basic-boxed-content {
  /* box-shadow: 0 0 2px #bbb; */
  /* -moz-box-shadow: 0 0 2px #bbb; */
  /* -webkit-box-shadow: 0 0 2px #bbb; */
  border-radius: 6px;
  padding: 15px;
  margin-top: 15px;
}

.basic-boxed-content h2 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.bg-primary-black {
  background: rgb(23 36 59 / 82%);
}

.bg-primary-black.blue {
  background: #040490;
}

.bg-primary-black.green {
  background: #036803;
}

.bg-primary-black.purple {
  background: purple;
}
.bg-primary-black.orange {
  background: orangered;
}

.ticker__element {
  margin-right: 20px !important;
}

.ticker {
  position: relative;
  overflow: hidden;
  height: 24px;
  max-width: 720px;
  margin: auto;
  text-wrap: nowrap;
}



.btn-purple {
  background-color: purple !important;
  color: white !important;
}
.btn-purple:hover {
  opacity: 0.7;
  transition: all ease-in 200ms;
}

.big-images-section img {
  width: 100%;
  min-height: 300px;
}

.zero-img-col {
  padding: 0 !important;
}

.big-images-section .basic-boxed-content.right {
  padding-right: 150px;
  padding-left: 50px;
}

.big-images-section .basic-boxed-content.left {
  padding-left: 150px;
  padding-right: 50px;
}
.big-images-section .basic-boxed-content {
  font-weight: 500;
  font-size: 17px;
  color: #1b1b1b9c;
}
@media only screen and (max-width: 600px) {
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top, width, height;
    position: relative !important;
    float: none;
    width: 100% !important;
    transform: none !important;
}
.carousel-section {
  padding-top: 0;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background: rgb(17 66 148);
  min-height: 600px;
}
.text-5xl {
  font-size: 34px;
  line-height: 1.1;
}

.text-2xl {
  font-size: 17px !important;
  line-height: 1.3;
}
.mt-5 {
  margin-top: 15px;
}
.pt-5 {
  padding-top: 15px !important;
}

.recharts-wrapper {
  width: auto !important;
}

.off-canvas-content-box .navbar-nav li {
  margin-right: 25px;
  border-bottom: 5px solid transparent;
  padding-top: 5px;
  font-weight: 600;
  color: #878787;
  padding-bottom: 10px;
  display: block !important;
  border-bottom: 1px solid #dddddd8f;
  float: none;
  margin-bottom: 0.6px;
  width: 100%;
}
.off-canvas-content-box .navbar-nav li a {
  width: 100%;
  display: block;
}
.text-xl {
  font-size: 15px;
}
.page-header {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dddddd6b;
  border-top: 1px solid #dddddd6b;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  font-size: 20px;
  background: #2E3093;
  margin-bottom: 0px;
}
.text-4xl {
  font-size: 34px !important;
  line-height: 1.3;
}
.react-grid-layout {
  height: auto !important;
}

.big-images-section .basic-boxed-content.right {
  padding-right: 0;
}

.offcanvas-header {
  display: flex;
  background: #2563eb;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  color: #ffffff;
}
.big-images-section .basic-boxed-content.left {
  padding-left: 0;
}
}

.bg-maroon {
  background: #c13737 !important;
}

.text-maroon {
  color: #c13737 !important;
}

.core-activities {
  background: #c13737;
  color: #ffffff;
}
.core-number {
  font-size: 24px;
  border-right: 1px solid #ffffffa7;
  padding-right: 6px;
  margin-right: 6px;
  font-weight: bold;
  line-height: 1;
}

.core-activity {
  font-size: 18px;
  line-height: 1.2;
}

.top-bar {
  background-color: #0f4cd1;
}

.header {
  background: rgb(37, 99, 235);
}
.bottom-nav {
  background: #0f4cd1;
  border-bottom: 1px solid #3463c8;
  box-shadow: 0px 1px 3px #0439ad;
}

h1, h2, h3, h4, h5, .header-font, .page-header {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
}

.header-font {
  font-size: 40px;
}

.bot-sep-box {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 3px;
  margin-bottom: 20px;
}

.modal-header {
  background: #0f4cd1;
  color: white;
}

.about-intro-overlay {
  position: absolute;
  background-image: url("../src/assets/img/about.png");
  background-size: cover;
  background-position: top left;
  height: 100%;
  width: 100%;
  opacity: 0.6;
}

.sillouhoutte-overlay {
  position: absolute;
  background-image: url("../src/assets/img/AfricaSilhouette-removebg-preview.png");
  /* background-size: cover; */
  /* background-position: top left; */
  height: 100%;
  width: 100%;
  opacity: 0.6;
}

.about-bg-with-overlay{
  position: relative;
}
.about-intro {
  position: relative;

  
}

.intro-border-bottom-curl {
  background: #ffffff;
  padding: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 2;
  border-radius: 0 0 150px 0;
}

body {
  line-height: 2;
}

.about-carousel-title {
  color: #0f4cd1;
  font-size: 23px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 22px;
}

.about-carousel-box {
  background: #ffffff;
  padding: 10px 20px;
  margin-right: 25px;
  border: 1px solid #ddd;
}